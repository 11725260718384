import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Container,
  RowOrCol,
  Col,
  ImageContainer,
  TextContainer,
  Header,
  Title,
  Description,
} from './styles'

export const ValueProps = ({ tagline, valueProps }) => (
  <Container>
    <Header>{tagline}</Header>
    <RowOrCol>
      {valueProps.map(({ title, image, description }) => (
        <Col>
          {image.gatsbyImageData && (
            <ImageContainer>
              <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
            </ImageContainer>
          )}
          <TextContainer>
            {title && <Title>{title}</Title>}
            {description && <Description>{description}</Description>}
          </TextContainer>
        </Col>
      ))}
    </RowOrCol>
  </Container>
)

ValueProps.propTypes = {
  tagline: PropTypes.string.isRequired,
  valueProps: PropTypes.arrayOf({
    title: PropTypes.string,
    image: PropTypes.shape({
      title: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    }),
    description: PropTypes.string,
  }),
}
