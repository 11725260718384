import React from 'react'
import PropTypes from 'prop-types'

import Analytics from 'src/utils/Analytics'

import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'
import { Hero, DetailImage, ValueProps, WhyWeBuiltIt, Testimonial } from 'src/components/Contentful'
import { DynamicCta } from '../../components/Contentful/atoms/DynamicCta'

class ContentfulLandingPage extends React.Component {
  componentDidMount() {
    const { pageContext } = this.props
    Analytics.track('Viewed Dynamic Landing Page', { title: pageContext.seoTitle })
  }

  render() {
    const { pageContext } = this.props
    if (!pageContext?.slug) return <LoadingAnimation />
    const overviewImageFile = pageContext.overviewImage.gatsbyImageData.images.fallback
    return (
      <>
        <SEO
          title={pageContext.seoTitle}
          description={pageContext.seoDescription}
          imagePath={overviewImageFile ? `https:${overviewImageFile.src}` : null}
          path={`/lp/${pageContext.slug}/`}
        />
        <Hero
          title={pageContext.descriptiveTitle}
          image={pageContext.overviewImage}
          descriptionRichText={pageContext.simpleDescription}
        />
        <DynamicCta ctaLink={pageContext.ctaLink} />
        <DetailImage image={pageContext.detailImage} />
        <ValueProps
          tagline={pageContext.funPunchyTagline}
          valueProps={[
            {
              title: pageContext.valuePropOneTitle,
              description: pageContext.valuePropOneDescription,
              image: pageContext.valuePropOneImage,
            },
            {
              title: pageContext.valuePropTwoTitle,
              description: pageContext.valuePropTwoDescription,
              image: pageContext.valuePropTwoImage,
            },
            {
              title: pageContext.valuePropThreeTitle,
              description: pageContext.valuePropThreeDescription,
              image: pageContext.valuePropThreeImage,
            },
          ]}
        />
        {pageContext.whyWeBuiltIt?.raw && <WhyWeBuiltIt bodyRichText={pageContext.whyWeBuiltIt} />}
        {pageContext.testimonialBody?.raw && (
          <Testimonial
            header={pageContext.testimonialHeader}
            bodyRichText={pageContext.testimonialBody}
            customerName={pageContext.testimonialCustomerName}
          />
        )}
        <DynamicCta ctaLink={pageContext.ctaLink} />
      </>
    )
  }
}

ContentfulLandingPage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    ctaLink: PropTypes.oneOf(['Calendar', 'Frame', 'FramePlus', 'CalMax']),
    seoTitle: PropTypes.string.isRequired,
    seoDescription: PropTypes.string.isRequired,
    overviewImage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      gatsbyImageData: PropTypes.shape({
        images: PropTypes.shape({
          fallback: PropTypes.shape({
            src: PropTypes.string.isRequired,
          }),
        }),
      }),
    }),
    descriptiveTitle: PropTypes.string.isRequired,
    simpleDescription: PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    detailImage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      gatsbyImageData: PropTypes.object.isRequired,
    }),
    funPunchyTagline: PropTypes.string.isRequired,
    valuePropOneTitle: PropTypes.string,
    valuePropOneDescription: PropTypes.string,
    valuePropOneImage: PropTypes.shape({
      title: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    }),
    valuePropTwoTitle: PropTypes.string,
    valuePropTwoDescription: PropTypes.string,
    valuePropTwoImage: PropTypes.shape({
      title: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    }),
    valuePropThreeTitle: PropTypes.string,
    valuePropThreeDescription: PropTypes.string,
    valuePropThreeImage: PropTypes.shape({
      title: PropTypes.string,
      gatsbyImageData: PropTypes.object,
    }),
    whyWeBuiltIt: PropTypes.shape({
      raw: PropTypes.string,
    }),
    testimonialHeader: PropTypes.string,
    testimonialBody: PropTypes.shape({
      raw: PropTypes.string,
    }),
    testimonialCustomerName: PropTypes.string,
  }),
}

export default ContentfulLandingPage
